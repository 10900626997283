.field_box {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: start;
  align-items: center;
  margin-left: 20px;
}

.form_label {
  width: 9rem;
  font-size: 18px;
  color: #ffffff;
}
