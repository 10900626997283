@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body,
#root,
.app .content {
  height: 100%;
  width: 100%;
  font-family: "source-sans-pro", sans-serif;
}
.app {
  display: flex;
  position: relative;
  width: 100%;
  overflow-y: hidden;
  height: 100vh;
}
::-webkit-scrollbar {
  /* display: none; */
  width: 0.6rem;
}
::-webkit-scrollbar-track {
  background: #e0e0e0;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.5rem;
}
::-webkit-scrollbar-track:hover {
  background: #555;
}
.sidebar,
.content {
  height: 100vh;
  overflow-y: scroll !important;
}
.sidebar {
  height: 100%;
  width: auto;
  /* width: 25%; */
  padding: 0rem 3.1rem 0rem 0rem !important;
  margin: 0 !important;
  /* padding: 0 !important; */
  font-family: "source-sans-pro", sans-serif;
}

.sidebar::-webkit-scrollbar,
.content::-webkit-scrollbar {
  display: none !important;
}
.transaction-container::-webkit-scrollbar {
  display: block !important;
}
.transaction-container::-webkit-scrollbar-thumb {
  background: #888 !important;
}
.transaction-container::-webkit-scrollbar-track {
  background: #e0e0e0 !important;
}
