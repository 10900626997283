.border-text {
  text-align: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #141b2d;
  padding: 0 5px;
}

.supplier_account_img_box {
  width: 100%;
  height: 20rem;
  border-radius: 10px;
  overflow: hidden;
}
.supplier_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}

#horizontal_line {
  width: 100%;
  border: 1px solid antiquewhite;
}

.details_heading {
  margin-top: 40px;
}

.certificate_image_box {
  width: 21rem;
}

.certification_style {
  width: 100%;
  height: 100%;
}
