.border-text {
  text-align: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #141b2d;
  padding: 0 5px;
}

.services_images {
  width: 100%;
  max-width: 10rem;
  height: auto;
}

.services_images img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.service_licence__images {
  width: 100%;
  max-width: 15rem;
  height: 16rem;
}

.service_licence__images img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 576px) {
  .details_side,
  .image_side {
    width: 100%;
  }
  .services_images {
    width: 100% !important;
    height: auto;
  }
}
